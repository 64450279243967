<kendo-upload *ngIf="!!modelOptions; else templateReactionForm"
  #kendoUpload
  [saveUrl]="saveUrl"
  [removeUrl]="removeUrl"
  [restrictions]="restrictions"
  [multiple]="multiple"
  [autoUpload]="autoUpload"
  [withCredentials]="withCredentials"
  [tabIndex]="tabIndex"
  [disabled]="disabled"
  [removeMethod]="removeMethod"
  [removeField]="removeField"
  [saveField]="saveField"
  [class]="combinedClassExtend"
  [(ngModel)]="fileList"
  (ngModelChange)="onFileListChange()"
  [ngModelOptions]="modelOptions"
  (select)="onSelect($event)"
  (success)="onSuccess($event)"
  (error)="onError($event)"
  (remove)="onRemove($event)"
  (clear)="onClear($event)"
  (upload)="onUpload($event)"
  (uploadProgress)="onUploadProgressFiles($event)"
>
  <ng-template kendoUploadFileTemplate let-files let-state="state">
    <ng-container *ngTemplateOutlet="customKendoUploadFileTemplate; context: { files: files, state: state }"></ng-container>
  </ng-template>
</kendo-upload>

<ng-template #templateReactionForm>
  <div [formGroup]="formGroupNameProp">
    <kendo-upload
      #kendoUpload
      [formControlName]="controlName"
      [saveUrl]="saveUrl"
      [removeUrl]="removeUrl"
      [restrictions]="restrictions"
      [multiple]="multiple"
      [autoUpload]="autoUpload"
      [withCredentials]="withCredentials"
      [tabIndex]="tabIndex"
      [disabled]="disabled"
      [removeMethod]="removeMethod"
      [removeField]="removeField"
      [saveField]="saveField"
      [class]="combinedClassExtend"
      [(ngModel)]="fileList"
      (ngModelChange)="onFileListChange()"
      (select)="onSelect($event)"
      (success)="onSuccess($event)"
      (error)="onError($event)"
      (remove)="onRemove($event)"
      (clear)="onClear($event)"
      (upload)="onUpload($event)"
      (uploadProgress)="onUploadProgressFiles($event)"
    >
      <ng-template kendoUploadFileTemplate let-files let-state="state">
        <ng-container *ngTemplateOutlet="customKendoUploadFileTemplate; context: { files: files, state: state }"></ng-container>
      </ng-template>
    </kendo-upload>
  </div>
</ng-template>

<ng-template #customKendoUploadFileTemplate let-files="files" let-state="state">
  <div class="name" [attr.uid-file]="files[0].uid">{{ files[0].name }}</div>
  <div class="actions">
    <div *ngIf="showUploadingStatus(state)" class="loader uploading"></div>
    <kendo-svgicon *ngIf="showUploadedStatus(state)" [icon]="allIcons.checkCircleIcon" size="xlarge" class="uploaded"></kendo-svgicon>
    <kendo-svgicon *ngIf="showErrorStatus(state)" [icon]="allIcons.warningTriangleIcon" size="xlarge" class="warning"></kendo-svgicon>
    <button
      kendoButton
      *ngIf="showRemoveButton(state) && !!removeUrl"
      [disabled]="isUploading"
      (click)="removeFilesByUid(files[0].uid)"
      [svgIcon]="allIcons.xIcon"
    >
    </button>
  </div>
</ng-template>