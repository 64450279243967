import { Component, ViewChild, AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first, merge, Subject } from 'rxjs';
import { Attachment } from 'src/app/core/models/attachment';
import { FileUploadCustom } from 'src/app/core/models/file-upload-custom';
import { Order } from 'src/app/core/models/order';
import { AttachmentsService } from 'src/app/core/services/attachments.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { BaseComponent } from '../base/base.component';
import { RemoveEvent, SuccessEvent, UploadProgressEvent } from '@progress/kendo-angular-upload';
import { CustomUploadComponent } from '../custom-upload/custom-upload.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-base-attachment',
  templateUrl: './base-attachment.component.html',
  styleUrls: ['./base-attachment.component.scss'],
})
export class BaseAttachmentComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild('uploadCtr') public uploadCtr: CustomUploadComponent;

  readonly msgConfirmDeleteAttachment = 'Are you sure you want to delete this attachment ?';
  
  protected isOpenAttachment = false;
  protected attachments: Attachment[] = [];
  protected currentOrderAttachment: Order;
  protected fileList: FileUploadCustom[] = [];

  protected saveAttachmentURL;
  protected removeAttachmentURL;
  protected progressDeleteAttachment: boolean = false;
  protected progressUploadAttachment: boolean = false;

  protected stopDownload = new Subject<void>();
  protected combineUnsubscribe = merge(this._unsubscribe, this.stopDownload);

  constructor(
    protected attachmentsService: AttachmentsService,
    protected dialogService: DialogService,
    protected spinner: NgxSpinnerService,
  ) {
    super();
  }
  
  getAttachments(site: string, order: Order): void {
    this.spinner.show();
    this.fileList = [];
    this.attachments = [];
    this.currentOrderAttachment = order;
    this.attachmentsService.fetch(site, order).subscribe(result => {
      if (result && result.length) {
        this.attachments = result;
        this.fileList = this.attachments.map(attachment => {
          if (!attachment.documentExtension.includes('.')) {
            attachment.documentExtension = '.' + attachment.documentExtension;
          }
          return {
            name: `${attachment.documentName}${attachment.documentExtension}`,
            extension: attachment.documentExtension,
            refSequence: attachment.refSequence,
            uid: attachment.id,
            id: attachment.id,
            size: attachment.documentSize ? attachment.documentSize / 1024 : 0
          } as FileUploadCustom;
        });
        this.downloadFile(site);
      }
    })
    .add(() => {
      this.spinner.hide();
    });
  }

  downloadFile(site: string) {
    setTimeout(() => {
      this.stopDownload.next();
      this.attachmentsService.addEventDownloadFile(this.combineUnsubscribe, this.attachments, site);
    }, 500);
  }

  onUploadProgressFiles(e: UploadProgressEvent) {
    this.progressUploadAttachment = true;
  }

  onSuccessUploadFiles(site: string, e: SuccessEvent) {
    if (e.operation === 'upload') {
      if (e.response && e.response.body) {
        if (e.response.body && Array.isArray(e.response.body) && e.files) {
          e.response.body.forEach((resFile, idx) => {
            resFile['name'] =  `${resFile.documentName}${resFile.documentExtension}`;
            resFile['preUid'] = e.files[idx].uid;
            resFile['uid'] = resFile.id;
          });
          e.files.forEach((resFile, idx) => {
            setTimeout(() => {
              let el = document.querySelector(`li.k-file[data-uid="${resFile.uid}"]`);
              el.setAttribute("data-uid", e.response.body[idx].id);
            }, 500 );
          });

          this.attachments = [...this.attachments, ...e.response.body];
          this.downloadFile(site);
          this.progressUploadAttachment = false;
        }
      }
    }
  }

  async onRemoveUploadFiles(site: string, e: RemoveEvent) {
    if (!this.progressDeleteAttachment) {
      e.preventDefault();

      const res = await this.dialogService
        .confirm(this.msgConfirmDeleteAttachment, 'No', 'Yes')
        .toPromise();

      if (!res.confirm) {
        // Cancel
        return false;
      }

      // Delete file
      if (e.files && e.files[0]) {
        this.progressDeleteAttachment = true;
        this.uploadCtr.getKendoUpload()?.removeFilesByUid(e.files[0].uid);
      }
    } else {
      this.spinner.show();
      let url = this.removeAttachmentURL;
      let fileId;
      if (e && e.files && e.files[0]) {
        const file: FileUploadCustom = e.files[0];
        fileId = file.id;
        if (this.fileList) {
          this.fileList = this.fileList.filter(f => f.uid !== file.uid);
          if (!fileId) {
            fileId = this.attachments.find(f => f.preUid == file.uid)?.id;
            if (!!fileId) {
              this.attachments = this.attachments.filter(f => (f.preUid !== file.uid));
            }
          } else {
            this.attachments = this.attachments.filter(f => (f.id !== file.uid));
          }
        }
        url = `${url}/${fileId}`;
      }

      e.preventDefault();

      this.progressUploadAttachment = false;

      if (!!fileId) {
        this.attachmentsService.deleteFile(url).pipe(first()).subscribe(() => {
          this.progressDeleteAttachment = false;
          this.downloadFile(site);
          this.spinner.hide();
        });
      } else {
        this.progressDeleteAttachment = false;
        this.downloadFile(site);
        this.spinner.hide();
      }
    }
  }

  onOpenAttachment(site: string, order: Order) {
    this.isOpenAttachment = true;
    this.fileList = [];
    this.attachments = [];
    this.saveAttachmentURL = `${environment.apiUrl}${site}/orders/${order.num}/attachments`;
    this.removeAttachmentURL = `${environment.apiUrl}${site}/orders/${order.num}/attachments`;
    if (order) {
      this.getAttachments(site, order);
    }
  }

  async onCloseAttachment(e) {
    if (this.progressUploadAttachment) {
      const confirmed = await this.attachmentsService.confirmDismissAttachment();
      if (!confirmed) {
        return false;
      }
    }

    this.isOpenAttachment = false;
    this.progressUploadAttachment = false;
    this.fileList = [];
    this.attachments = [];
  }

  onFileSelect(event: any) {
    const file = event.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'zip') {
      event.preventDefault();
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stopDownload.next();
    this.stopDownload.complete();
  }
  
}