import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, fromEvent, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Attachment } from 'src/app/core/models/attachment';
import { Order } from '../models/order';
import { environment } from 'src/environments/environment';
import { FileUploadCustom } from '../models/file-upload-custom';
import { DOCUMENT } from '@angular/common';
import { Quote } from '../models/quote';
import { DialogService } from './dialog.service';
import { CustomConfirmDialogComponent } from 'src/app/shared/components/custom-confirm-dialog/custom-confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  readonly iconPendingUpload = `warningTriangleIcon`;
  readonly cancelPendingUpload = `Stay on Page`;
  readonly confirmPendingUpload = `Leave Anyway`;
  readonly titlePendingUpload = `<kendo-icon name="warning"></kendo-icon><b>Upload in Progress</b>`;
  readonly msgPendingUpload = `
    One or more files are still uploading. If you leave now, the upload will be canceled, and your files will not be saved.
    <br>
    <b>Do you still want to leave?</b>
  `;

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document, private dialogService: DialogService) { }

  public fetch(site: string, order: Order): Observable<Attachment[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${order.num}/attachments`).pipe(map(
      (response: Attachment[]) => {
        response.map(file => {
          if (!file.documentExtension.includes('.')) {
            file.documentExtension = '.' + file.documentExtension;
          }
          return file;
        });
        return response;
      }
    ));
  }

  public get(site: string, order: Order, id: string): Observable<Attachment> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${order.num}/attachments/${id}`).pipe(map(
      (response: Attachment) => response
    ));
  }
  public save(site: string, order: Order, attachments: Attachment[], paymentId: string): Observable<any> {
    return this.http.put<Attachment[]>(`${environment.apiUrl}${site}/orders/${order.num}/payments/${paymentId}/attachments`, attachments);
  }

  deleteFile(deleteUrl: string) {
    return this.http.delete(`${deleteUrl}`).pipe(map(
      (response) => response
    ));
  }

  public downloadFile(site: string, id: string, name: string, type: string) {
    type = type?.replace('.', '').trim();
    const url = `/${site}/attachments/${id}?name=${name}&type=${type}`;
    window.open(url, '_blank');
  }

  public downloadFileUrl(url: string) {
    window.open(url, '_blank');
  }

  public async confirmDismissAttachment() {
    const dialog =  this.dialogService.open({
      content: CustomConfirmDialogComponent
    });
    const instance = dialog.content.instance;
    instance.title = this.titlePendingUpload;
    instance.message = this.msgPendingUpload;
    instance.cancelText = this.cancelPendingUpload;
    instance.confirmText = this.confirmPendingUpload;
    instance.iconName = this.iconPendingUpload;

    const result = await firstValueFrom(dialog.result); 
    return !!result['confirmed'];
  }

  public addEventDownloadFile(destroyed: Subject<void> | Observable<void>, attachments: FileUploadCustom[] | Attachment[], currentSite: string) {
    fromEvent(this.document.querySelectorAll('li.k-file'), 'click').pipe(
      takeUntil(destroyed)
    ).subscribe((event: Event) => {
      const pattern = / +(?= )/g;
      const fileName = (event.target as HTMLElement).innerText.replace(pattern,'').trim();
      if (attachments[0] && 'uid' in attachments[0]) {
        let selection = (attachments as FileUploadCustom[]).find(r => fileName === r.name.replace(pattern,'').trim()) as FileUploadCustom;
        if (selection) {
          this.downloadFile(currentSite, selection.id, selection.documentName ?? selection.name, selection.documentExtension ?? selection.extension);
        }
      } else {
        let selection = (attachments as Attachment[])
          .find(r => fileName === (r.documentName + r.documentExtension).replace(pattern,'').trim()) as Attachment;
          if (selection) {
            this.downloadFile(currentSite, selection.id, selection.documentName, selection.documentExtension);
          }
      }
    });
  }
}
