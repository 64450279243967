import { Component, OnInit, SecurityContext } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as svgIcons from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
  styleUrls: ['./custom-confirm-dialog.component.scss'],
})
export class CustomConfirmDialogComponent extends DialogContentBase implements OnInit {
  public message: string = 'Are you sure?';
  public title: string = '';
  public confirmText: string = 'Yes';
  public cancelText: string = 'No';
  public sanitizedMessage: any;
  public sanitizedTitle: any;
  public iconName: string = '';

  allIcons = svgIcons;

  constructor(public dialog: DialogRef, private sanitizer: DomSanitizer) {
    super(dialog);
  }

  ngOnInit() {
    this.sanitizedMessage = this.sanitizer.sanitize(SecurityContext.HTML, this.message);
    this.sanitizedTitle = this.sanitizer.sanitize(SecurityContext.HTML, this.title);
  }

  public onConfirm(): void {
    this.dialog.close({ confirmed: true });
  }

  public onCancel(): void {
    this.dialog.close({ confirmed: false });
  }
}