import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { UploadComponent, UploadEvent, SuccessEvent, ErrorEvent, RemoveEvent, ClearEvent, SelectEvent, UploadProgressEvent, FileState } from '@progress/kendo-angular-upload';
import { FileUploadCustom } from 'src/app/core/models/file-upload-custom';
import * as svgIcons from '@progress/kendo-svg-icons';
import { FormGroup, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-upload',
  templateUrl: './custom-upload.component.html',
  styleUrls: ['./custom-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomUploadComponent implements AfterViewInit {
  @ViewChild('kendoUpload', { static: false }) kendoUpload: UploadComponent;
  @ViewChild('kendoUpload', { read: ElementRef, static: false }) kendoUploadEl: ElementRef;

  @Input() saveUrl: string;
  @Input() removeUrl: string;
  @Input() restrictions: any;
  @Input() multiple: boolean = true;
  @Input() autoUpload: boolean = true;
  @Input() withCredentials: boolean = true;
  @Input() tabIndex: number;
  @Input() disabled: boolean = false;
  @Input() removeMethod: string = 'POST';
  @Input() removeField: string = 'fileNames';
  @Input() saveField: string = 'files';
  @Input() fileList: FileUploadCustom[];
  @Input() modelOptions: any;
  @Input() classExtend: any;
  @Input() formGroupNameProp: UntypedFormGroup;
  @Input() controlName: string;

  @Output() select = new EventEmitter<SelectEvent>();
  @Output() success = new EventEmitter<SuccessEvent>();
  @Output() error = new EventEmitter<ErrorEvent>();
  @Output() remove = new EventEmitter<RemoveEvent>();
  @Output() clear = new EventEmitter<ClearEvent>();
  @Output() upload = new EventEmitter<UploadEvent>();
  @Output() uploadProgress = new EventEmitter<UploadProgressEvent>();
  @Output() fileListChange = new EventEmitter<FileUploadCustom[]>();

  files: any[] = [];

  totalFilesSelected: number = 0;
  filesUploaded: number = 0;

  filesUploading = 0;
  isUploading = false;

  allIcons = svgIcons;

  get combinedClassExtend() {
    let disableCssDropZone = this.isUploading ? 'disable-dropzone' : '';
    return `${this.classExtend} ${disableCssDropZone}`;
  }

  ngAfterViewInit() {}

  onFileListChange() {
    this.fileListChange.emit(this.fileList); // Emit the updated value
  }

  onSelect(event: SelectEvent): void {
    this.totalFilesSelected = event.files.length;
    this.filesUploaded = 0;
    this.files = event.files;
    console.log('Selected files:', this.files);
    this.select.emit(event);
  }

  onSuccess(event: SuccessEvent): void {
    this.filesUploaded++;
    if (this.filesUploaded === this.totalFilesSelected) {
      const msg = `${this.totalFilesSelected} uploads complete`;
      this.replaceMessageStatusDropZone(msg);
    }

    this.filesUploading--;
    if (this.filesUploading === 0) {
      this.isUploading = false;
    }

    this.success.emit(event);
  }

  replaceMessageStatusDropZone(msg: string, timeout = 100) {
    if (this.kendoUploadEl) {
      // Find the "Done" element and change its text
      const doneElement = this.kendoUploadEl.nativeElement.querySelector('.k-upload-dropzone .k-upload-status');
      if (doneElement) {
        // Get all child nodes of the "Done" element
        const childNodes = doneElement.childNodes;
  
        // Iterate through the child nodes and replace only text nodes
        setTimeout(() => {
          childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
              node.textContent = msg;
            }
          });
        }, timeout);
      }
    }
  }

  onError(event: ErrorEvent): void {
    this.filesUploading--;
    if (this.filesUploading === 0) {
      this.isUploading = false;
    }

    this.error.emit(event);
  }

  onRemove(event: RemoveEvent): void {
    this.remove.emit(event);
  }

  onClear(event: ClearEvent): void {
    this.clear.emit(event);
  }

  onUpload(event: UploadEvent): void {
    this.filesUploading++;
    if (this.filesUploading > 0) {
      this.isUploading = true;
    }

    this.upload.emit(event);
    const msg = `Uploading ${this.totalFilesSelected} items`;
    this.replaceMessageStatusDropZone(msg, 200);
  }

  public uploadFiles(): void {
    if (this.kendoUpload) {
      this.kendoUpload.uploadFiles();
    }
  }

  onUploadProgressFiles(event: UploadProgressEvent): void {
    console.log('Upload Progress:', event.percentComplete, 'files:', event.files);
    this.uploadProgress.emit(event);
    const msg = `Uploading ${this.totalFilesSelected} items`;
    this.replaceMessageStatusDropZone(msg, 200);
  }

  public clearFiles(): void {
    if (this.kendoUpload) {
      this.kendoUpload.clearFiles();
    }
  }

  public getKendoUpload(): UploadComponent {
    if (this.kendoUpload) {
      return this.kendoUpload;
    }
  }

  // removeFile(uid: string) {
  //   this.kendoUpload.removeFilesByUid(uid);
  // }
  // removeFile(fileName: string, index: number) {
  //   if (index >= 0 && index < this.fileList.length && this.fileList[index].name === fileName) {
  //     this.fileList.splice(index, 1);
  //   }
  // }

  removeFilesByUid(uid: string) {
    this.kendoUpload.removeFilesByUid(uid);
  }

  public showRemoveButton(state: FileState): boolean {
    return [FileState.Failed, FileState.Initial, FileState.Uploaded].includes(state) ? true : false;
  }

  public showUploadingStatus(state: FileState): boolean {
    return state === FileState.Uploading ? true : false;
  }

  public showUploadedStatus(state: FileState): boolean {
    return state === FileState.Uploaded ? true : false;
  }

  public showErrorStatus(state: FileState): boolean {
    return state === FileState.Failed ? true : false;
  }
}