import { Injectable } from '@angular/core';
import { DialogService as DS, DialogCloseResult, DialogAction, DialogResult, DialogSettings } from '@progress/kendo-angular-dialog';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SimpleDialogResult {
  confirm: boolean;
  isCancel: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialogService: DS) { }

  public info(content: string, title = 'Information'): Observable<SimpleDialogResult> {
    const ref = this.dialogService.open({
      title: title,
      content,
      actions: [
        { text: 'Ok', themeColor: 'primary' }
      ]
    });
    return ref.result.pipe(
      map(() => {
        return { isCancel: true } as SimpleDialogResult;
      })
    );
  }

  public infoAsync(content: string, title = 'Information'): Promise<SimpleDialogResult> {
    return firstValueFrom(this.info(content, title));
  }

  public warning(content: string, title = 'Warning'): Observable<SimpleDialogResult> {
    const ref = this.dialogService.open({
      title: title,
      content,
      actions: [
        { text: 'Ok', themeColor: 'primary' }
      ]
    });
    return ref.result.pipe(
      map(() => {
        return { isCancel: true } as SimpleDialogResult;
      })
    );
  }

  public warningAsync(content: string, title = 'Warning'): Promise<SimpleDialogResult> {
    return firstValueFrom(this.warning(content, title));
  }

  public confirm(content: string, txtNo: string = 'No', txtYes: string = 'Yes', title = 'Please confirm'): Observable<SimpleDialogResult> {
    const ref = this.dialogService.open({
      title: title,
      content,
      actions: [
        { text: txtNo },
        { text: txtYes, themeColor: 'primary' }
      ]
    });
    return ref.result.pipe(map((r: DialogResult) => {
      if (r instanceof DialogCloseResult) {
        return { isCancel: true } as SimpleDialogResult;
      }
      const action = r as DialogAction;
      return {
        isCancel: false,
        confirm: action.themeColor == 'primary'
      } as SimpleDialogResult;
    }));
  }

  public confirmAsync(content: string, txtNo: string = 'No', txtYes: string = 'Yes', title = 'Please confirm'): Promise<SimpleDialogResult> {
    return firstValueFrom(this.confirm(content, txtNo, txtYes, title));
  }

  public open(options: DialogSettings): DialogRef {
    return this.dialogService.open(options);
  }

  public openAsync(options: DialogSettings): Promise<DialogResult> {
    let ref = this.open(options);
    return firstValueFrom(ref.result);
  }
}
